body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  min-height: -webkit-fill-available;
  --scrollbarBG: #61ca81;
  --thumbBG: rgb(255, 255, 255);
}

body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.underline {
  position: relative;
  cursor: pointer;
}

.underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  color: #e6c574 !important;
  bottom: 0;
  left: 0;
  background-color: #e6c574;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.underline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
