footer {
  padding: 94px 5px 60px;
}

footer .footer-logo a {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
footer .footer-logo a img {
  margin-right: 16px;
  width: 58px;
}
footer .footer-logo a span {
  font-size: 28px;
  line-height: 68px;
  font-weight: 700;
  letter-spacing: -3%;
  color: #216c38;
  font-family: "Mulish";
}
footer h5 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Mulish";
  margin-bottom: 31px;
}
footer .footer-nav {
  max-width: 200px;
  margin: 0 auto;
}
footer .footer-nav-dark li {
  margin-bottom: 23px;
}

footer .footer-nav-light li {
  margin-bottom: 23px;
}

footer .footer-nav-dark li a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: "poppins";
  color: white;
}

footer .footer-nav-light li a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: "poppins";
}

footer .footer-contact {
  list-style: none;
  padding-left: 0;
  margin-bottom: 26px;
}
footer .footer-links {
  list-style: none;
  padding-left: 0;
}
a[href="#anchor-our-services"],
a[href="index.php"],
a[href="about.php"],
a[href="about.php#anchor-contact-us"] {
  text-decoration: none;
}
footer .footer-contact li {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: "poppins";
}
footer .footer-contact li a {
  margin-bottom: 0;
}
footer .link-container {
  display: flex;
}
footer .link-to-clipboard {
  display: none;
}
footer .link-container:hover div.link-to-clipboard {
  margin-left: 8px;
  display: block;
  cursor: copy;
}
footer .social-icons {
  display: flex;
  align-items: center;
  padding-left: 0;
}
footer .social-icons li a {
  margin-bottom: 0;
}
footer .social-icons li {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #216c38;
  margin-right: 14px;
}

footer .social-icons li a {
  color: #fff;
  font-size: 14px;
}
footer .footer-nav {
  margin: 40px 0 0;
}
